import { Component, OnInit } from '@angular/core';

import { AuthenticationService } from '../../services/authentication.service';
import { DataService } from '../../services/data.service';

declare interface RouteInfo {
  path: string;
  title: string;
  icon: string;
  class: string;
  show: string;
}
export const ROUTES: RouteInfo[] = [
  { path: '/home', title: 'Home', icon: 'design_app', class: '', show: 'all'},
  { path: '/buzzer-system', title: 'Buzzer System', icon: 'design_app', class: '', show: 'all'},
  { path: '/login', title: 'Login', icon: 'objects_key-25', class: '', show: 'logged_out'},
  { path: '/quizzer-dashboard', title: 'Quizzer Dashboard', icon: 'business_chart-bar-32', class: '', show: 'quizzer'},
  { path: '/team-dashboard', title: 'Team Dashboard', icon: 'business_chart-bar-32', class: '', show: 'coach'},
  { path: '/create-user', title: 'Create User', icon: 'users_single-02', class: '', show: 'logged_in'},
  { path: '/user-profile', title: 'User Profile', icon: 'users_single-02', class: '', show: 'logged_in'},
  { path: '/reset-password', title: 'Reset Password', icon: 'users_single-02', class: '', show: 'never'},
  { path: '/change-password', title: 'Change Password', icon: 'users_single-02', class: '', show: 'never'},
  { path: '/payment', title: 'Payment Received', icon: 'users_single-02', class: '', show: 'never'},
  { path: '/resources', title: 'Resources', icon: 'education_agenda-bookmark', class: '', show: 'coach'},
  { path: '/privacy', title: 'Privacy Policy', icon: 'files_paper', class: '', show: 'all'},
];

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.css']
})
export class SidebarComponent implements OnInit {
  menuItems: any[];
  beginnnerQuizzers: any[];
  juniorQuizzers: any[];
  intermediateQuizzers: any[];
  experiencedWPFQuizzers: any[];
  experiencedUPCIQuizzers: any[];

  currentUser: any;

  sub: any;

  filterRoutes() {
    if (this.currentUser != null) {
      this.menuItems = ROUTES.filter(menuItem => menuItem.show =='all'
        || menuItem.show == 'logged_in'
        || (this.currentUser.UserType >= 1 && menuItem.show == 'coach') // coach or admin
        || (this.currentUser.UserType == 0 && menuItem.show == 'quizzer'));
    } else {
      this.menuItems = ROUTES.filter(menuItem => menuItem.show =='all'
        || menuItem.show == 'logged_out');
    }
  }

  constructor(private authenticationService: AuthenticationService,
    private dataService: DataService) {
    this.beginnnerQuizzers = new Array();
    this.juniorQuizzers = new Array();
    this.intermediateQuizzers = new Array();
    this.experiencedWPFQuizzers = new Array();
    this.experiencedUPCIQuizzers = new Array();
  }

  ngOnInit() {
    this.sub = this.authenticationService.currentUser.subscribe(x => {
      this.currentUser = x;
      this.filterRoutes();
      if (this.currentUser && this.currentUser.UserType >= 1) { // coach or admin
        this.dataService.getQuizzers(this.currentUser.AuthCode).subscribe(x => {
          this.beginnnerQuizzers = new Array();
          this.juniorQuizzers = new Array();
          this.intermediateQuizzers = new Array();
          this.experiencedWPFQuizzers = new Array();
          this.experiencedUPCIQuizzers = new Array();
                for (var i = 0; i < x['Data'].length; i++) {
            var q = x['Data'][i];
            if (+q.Material == 0)
              this.beginnnerQuizzers.push(q);
            else if (+q.Material == 1)
              this.juniorQuizzers.push(q);
            else if (+q.Material == 2)
              this.intermediateQuizzers.push(q);
              else if (+q.Material == 3)
              this.experiencedWPFQuizzers.push(q);
            else
              this.experiencedUPCIQuizzers.push(q);
          }
      });
      } else {
        this.beginnnerQuizzers = new Array();
        this.juniorQuizzers = new Array();
        this.intermediateQuizzers = new Array();
        this.experiencedWPFQuizzers = new Array();
        this.experiencedUPCIQuizzers = new Array();
      }
    });
  }

  public ngOnDestroy() {
    this.sub.unsubscribe();
  }  

  isMobileMenu() {
    if (window.innerWidth > 991) {
      return false;
    }
    return true;
  };
}
