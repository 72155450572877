import { Injectable } from '@angular/core';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { CONFIG } from '../app.config';

@Injectable({ providedIn: 'root' })
export class DataService {
    private currentUserSubject: BehaviorSubject<any>;
    public currentUser: Observable<any>;

    constructor(private http: HttpClient) { }

    getExerciseTotalTime(authcode: string, userId:number, material:number = -1, maxDays: number = 365) {
        let json = {
            'AuthCode': authcode,
            'MaxDays': maxDays,
            'UserID' : userId,
            'Material' : material,
        };

        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
            })
        };

        return this.http.post<any>(`${CONFIG.apiUrl}/data_get_exercise_time`, json, httpOptions)
            .pipe(map(response => {
                return response;
            }));
    }

    getExerciseReviewedCards(authcode: string, userId: number, material:number = -1, maxDays: number = 365, cardType: number = 1) {
        let json = {
            'AuthCode': authcode,
            'MaxDays': maxDays,
            'UserID' : userId,
            'Material' : material,
            'CardType': cardType,
        };

        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
            })
        };

        return this.http.post<any>(`${CONFIG.apiUrl}/data_get_reviewed_cards`, json, httpOptions)
            .pipe(map(response => {
                return response;
            }));
    }

    getExerciseTotalCards(authcode: string, userId: number, material:number = -1, maxDays: number = 365, cardType: number = 1) {
        let json = {
            'AuthCode': authcode,
            'MaxDays': maxDays,
            'UserID' : userId,
            'Material' : material,
            'CardType': cardType,
        };

        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
            })
        };

        return this.http.post<any>(`${CONFIG.apiUrl}/data_get_total_cards`, json, httpOptions)
            .pipe(map(response => {
                return response;
            }));
    }

    getExerciseNewCards(authcode: string, userId: number, material:number = -1, maxDays: number = 365, cardType: number = 1) {
        let json = {
            'AuthCode': authcode,
            'MaxDays': maxDays,
            'UserID' : userId,
            'Material' : material,
            'CardType': cardType,
        };

        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
            })
        };

        return this.http.post<any>(`${CONFIG.apiUrl}/data_get_new_cards`, json, httpOptions)
            .pipe(map(response => {
                return response;
            }));
    }

    getQuizzers(authcode: string) {
        let json = {
            'AuthCode': authcode,
        };

        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
            })
        };

        return this.http.post<any>(`${CONFIG.apiUrl}/data_get_quizzers`, json, httpOptions)
            .pipe(map(response => {
                return response;
            }));
    }    

}
