import { Injectable } from '@angular/core';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { CONFIG } from '../app.config';

@Injectable({ providedIn: 'root' })
export class AuthenticationService {
    private currentUserSubject: BehaviorSubject<any>;
    public currentUser: Observable<any>;

    constructor(private http: HttpClient) {
        this.currentUserSubject = new BehaviorSubject<any>(JSON.parse(localStorage.getItem('currentUser')));
        this.currentUser = this.currentUserSubject.asObservable();
    }

    public get currentUserValue(): any {
        return this.currentUserSubject.value;
    }

    login(username: string, password: string) {
        let json = {'Username': username, 'Password': password};

        const httpOptions = {
            headers: new HttpHeaders({  
              'Content-Type':  'application/json',
            })
          };

        return this.http.post<any>(`${CONFIG.apiUrl}/user_login`, json, httpOptions)
            .pipe(map(response => {
                // login successful if there's a jwt token in the response
                if (response && response.Status && response.Status == 'success') {
                    // store the user
                    localStorage.setItem('currentUser', JSON.stringify(response.Data));
                    this.currentUserSubject.next(response.Data);
                }
                return response;
            }));
    }

    createUser(username: string, email: string, firstName: string, lastName: string, password: string, type: number, teamID: number) {
        let json = {
            'Username': username,
            'Email': email,
            'FirstName': firstName,
            'LastName': lastName,
            'Password': password,
            'TeamID': teamID,
            'Material': type,
        };

        const httpOptions = {
            headers: new HttpHeaders({  
              'Content-Type':  'application/json',
            })
          };

        return this.http.post<any>(`${CONFIG.apiUrl}/user_create_web`, json, httpOptions)
            .pipe(map(response => {
                // login successful if there's a jwt token in the response
                if (response && response.Status && response.Status == 'success') {

                }
                return response;
            }));
    }    

    update(authcode: string, firstname: string, lastname: string, email: string, password: string = '') {
        let json = {
            'AuthCode': authcode,
            'FirstName': firstname,
            'LastName': lastname,
            'Email': email,
            'Password': password
        };

        const httpOptions = {
            headers: new HttpHeaders({  
              'Content-Type':  'application/json',
            })
          };

        return this.http.post<any>(`${CONFIG.apiUrl}/user_update`, json, httpOptions)
            .pipe(map(response => {
                // login successful if there's a jwt token in the response
                if (response && response.Status && response.Status == 'success') {
                    // store the user
                    localStorage.setItem('currentUser', JSON.stringify(response.Data));
                    this.currentUserSubject.next(response.Data);
                }
                return response;
            }));
    }

    resetPassword(email: string) {
        let json = {
            'Email': email,
        };

        const httpOptions = {
            headers: new HttpHeaders({  
              'Content-Type':  'application/json',
            })
          };

        return this.http.post<any>(`${CONFIG.apiUrl}/user_reset_password`, json, httpOptions)
            .pipe(map(response => {
                return response;
            }));
    }    

    changePassword(authcode: string, password: string) {
        let json = {
            'AuthCode': authcode,
            'Password': password,
        };

        const httpOptions = {
            headers: new HttpHeaders({  
              'Content-Type':  'application/json',
            })
          };

        return this.http.post<any>(`${CONFIG.apiUrl}/user_change_password`, json, httpOptions)
            .pipe(map(response => {
                return response;
            }));
    }    

    logout() {
        // remove user from local storage to log user out
        localStorage.removeItem('currentUser');
        this.currentUserSubject.next(null);
    }

    paymentReceived(token: any) {
        let json = {
            'StripeToken': token,
        };

        const httpOptions = {
            headers: new HttpHeaders({  
              'Content-Type':  'application/json',
            })
          };

        return this.http.post<any>(`${CONFIG.apiUrl}/payment_received`, json, httpOptions)
            .pipe(map(response => {
                return response;
            }));
   }

   createTeam(token_id: string, team_name: string, pastor: string, city: string, state: string, team_code:string,
    email:string, username:string, first_name:string, last_name:string, password:string, amount:Number) {
    let json = {
        'TokenID': token_id,
        'TeamName': team_name,
        'Pastor': pastor,
        'City': city,
        'State': state,
        'TeamCode': team_code,
        'Email': email,
        'Username': username,
        'FirstName': first_name,
        'LastName': last_name,
        'Password': password,
        'Amount': amount,
    };

    const httpOptions = {
        headers: new HttpHeaders({  
          'Content-Type':  'application/json',
        })
      };

    return this.http.post<any>(`${CONFIG.apiUrl}/team_create`, json, httpOptions)
        .pipe(map(response => {
            // login successful if there's a jwt token in the response
            if (response && response.Status && response.Status == 'success') {
                // store the user
                localStorage.setItem('currentUser', JSON.stringify(response.Data));
                this.currentUserSubject.next(response.Data);
            }
           return response;
        }));
    }

}